import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const BoardQuizCategories = () => import("./components/BoardQuizCategories.vue")
const BoardQuiz = () => import("./components/BoardQuiz.vue")
const BoardAdminQuizCategories = () => import("./components/BoardAdminQuizCategories.vue")
const BoardAdminUsers = () => import("./components/BoardAdminUsers.vue")
const BoardAdminLeagues = () => import("./components/BoardAdminLeagues.vue")
const BoardAdminBattles = () => import("./components/BoardAdminBattles.vue")
const BoardAdminSchools = () => import("./components/BoardAdminSchools.vue")
const AddCategory = () => import("./components/AddCategory.vue");
const AddQuestion = () => import("./components/AddQuestion.vue");
const ManageQuestions = () => import("./components/ManageQuestions.vue");
const Leaderboard = () => import("./components/Leaderboard.vue")
const Battles = () => import("./components/Battles.vue")
const Leagues = () => import("./components/Leagues.vue")
const League = () => import("./components/League.vue")
const Battle = () => import("./components/Battle.vue")
const AddSchool = () => import("./components/AddSchool.vue")
const BoardAdminStaff = () => import("./components/BoardAdminStaff.vue")
const BoardAdminStudents = () => import("./components/BoardAdminStudents.vue")
const School = () => import("./components/School.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/register/:id",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/adminCategories",
    name: "admincategories",
    // lazy-loaded
    component: BoardAdminQuizCategories,
  },
  {
    path: "/adminUsers",
    name: "adminusers",
    // lazy-loaded
    component: BoardAdminUsers,
  },
  {
    path: "/adminLeagues",
    name: "adminleagues",
    // lazy-loaded
    component: BoardAdminLeagues,
  },
  {
    path: "/adminBattles",
    name: "adminbattles",
    // lazy-loaded
    component: BoardAdminBattles,
  },
  {
    path: "/adminSchools",
    name: "adminschools",
    // lazy-loaded
    component: BoardAdminSchools,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/quizCategories",
    name: "quizcategories",
    // lazy-loaded
    component: BoardQuizCategories,
  },
  {
    path: "/quiz/:id",
    name: "quiz",
    // lazy-loaded
    component: BoardQuiz,
  },
  {
    path: "/quiz/:id/:battleId",
    name: "quizBattle",
    // lazy-loaded
    component: BoardQuiz,
  },
  {
    path: "/AddCategory",
    name: "addCategory",
    component: AddCategory,
  },
  {
    path: "/AddCategory/:id",
    name: "addCategoryId",
    component: AddCategory,
  },
  {
    path: "/adminCategories/:msg",
    name: "admincategoriesMsg",
    component: BoardAdminQuizCategories,
  },
  {
    path: "/manageQuestions/:id",
    name: "manageQuestions",
    component: ManageQuestions,
  },
  {
    path: "/manageQuestions/:id/:response",
    name: "manageQuestionsResponse",
    component: ManageQuestions,
  },
  {
    path: "/AddQuestion/:cid",
    name: "addQuestion",
    component: AddQuestion,
  },
  {
    path: "/AddQuestion/:cid/:id",
    name: "addQuestionId",
    component: AddQuestion,
  },
  {
    path: "/Leaderboard/",
    name: "Leaderboard",
    component: Leaderboard
  },
  {
    path: "/Battles/",
    name: "Battles",
    component: Battles
  },
  {
    path: "/Leagues/",
    name: "Leagues",
    component: Leagues
  },
  {
    path: "/league/:id",
    name: "league",
    component: League,
  },
  {
    path: "/battle/:id",
    name: "battle",
    component: Battle,
  },
  {
    path: "/AddSchool",
    name: "addSchool",
    component: AddSchool,
  },
  {
    path: "/AddSchool/:id",
    name: "AddSchoolId",
    component: AddSchool,
  },
  {
    path: "/AdminStaff/:id",
    name: "AdminStaffId",
    component: BoardAdminStaff,
  },
  {
    path: "/AdminStudents/:id",
    name: "AdminStudentsId",
    component: BoardAdminStudents,
  },
  {
    path: "/School",
    name: "School",
    component: School,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;